<template>
    <div>
        <styled-interface hide-toolbar>
            <template #interface-heading>
                Reseller Onboarding
            </template>
            <div>
                <styled-card>
                    <template #heading>
                        Reseller Invites
                    </template>
                    <template #action-buttons>
                        <action-button
                            icon="add-permission"
                            @click="addResellerDialog = true">
                            Add Reseller
                        </action-button>
                        <action-button
                            icon="refresh"
                            class="ml-3"
                            :loading="loadingInvites"
                            @click="getInvites">
                            Refresh
                        </action-button>
                    </template>
                    <custom-table
                        name="reseller-invites"
                        min-width="1200px"
                        :items="invites"
                        :fields="fields"
                        :loading="loadingInvites"
                        :notification="notification"
                        :notification-type="notificationType"
                        :rows-per-page="50"
                        initial-sorting="Date Created"
                        initial-sort-direction="desc"
                        has-filters
                        slim-cells />
                </styled-card>
                <styled-slideout v-model="addResellerDialog">
                    <div class="pa-5">
                        <v-alert
                            :value="error"
                            type="error">
                            Error creating reseller invite. Please contact support.
                        </v-alert>
                        <h2>Invite Reseller</h2>
                        <p class="mb-4">
                            Create a reseller invite link for a potential new reseller. This link will allow them
                            to login with Facebook and set up a new reseller in BuyerBridge.
                        </p>
                        <v-text-field
                            v-model="name"
                            label="Reseller Name"
                            class="styled-field mb-4" />
                        <div class="text-xs-center mt-5">
                            <styled-button
                                :disabled="!name"
                                :loading="loadingCreateInvite"
                                @click="createInvite()">
                                Create
                            </styled-button>
                        </div>
                    </div>
                </styled-slideout>
            </div>
        </styled-interface>
    </div>
</template>

<script>
import StyledInterface from '@/components/globals/StyledInterface';
import StyledCard from '@/components/globals/StyledCard';
import ActionButton from '@/components/globals/ActionButton';
import StyledButton from '@/components/globals/StyledButton';
import StyledSlideout from '@/components/globals/StyledSlideout';
import CustomTable from '@/components/globals/CustomTable/index.vue';
import HTTP from '@/http';

    export default {
        components: {
            StyledInterface,
            StyledCard,
            ActionButton,
            StyledButton,
            StyledSlideout,
            CustomTable,
        },
        data() {
            return {
                name: '',
                addResellerDialog: false,
                loadingInvites: false,
                loadingCreateInvite: false,
                error: false,
                invites: [],
                fields: [
                    {
                        id: 'name',
                        header: 'Name',
                        value: 'name',
                        align: 'left',
                        width: '20%',
                        sortable: true,
                        type: 'single',
                    },
                    {
                        id: 'user_name',
                        header: 'Created By',
                        value: 'user_name',
                        align: 'left',
                        width: '17%',
                        sortable: true,
                        filter: 'multiple',
                        placeholder: 'User',
                        type: 'single',
                    },
                    {
                        id: 'created_at',
                        header: 'Date Created',
                        value: 'created_at',
                        align: 'left',
                        width: '13%',
                        sortable: true,
                        type: 'date',
                    },
                    {
                        id: 'uuid',
                        header: 'Link',
                        value: 'uuid',
                        align: 'left',
                        width: '40%',
                        type: 'component',
                        component: () => import('@/components/resellers/onboarding/InviteLink.vue'),
                    },
                    {
                        id: 'copy',
                        header: 'Copy Link',
                        value: 'uuid',
                        align: 'center',
                        width: '10%',
                        type: 'component',
                        component: () => import('@/components/resellers/onboarding/InviteLink.vue'),
                        props: {
                            linkType: 'copy'
                        }
                    },
                ],
                notification: '',
                notificationType: '',
            };
        },
        created() {
            this.getInvites();
        },
        methods: {
            async getInvites() {
                try {
                    this.invites = [];
                    this.loadingInvites = true;
                    const response = await (new HTTP).get('/agency-invites');
                    const invites = response.data.map(invite => {
                        invite.user_name = invite.user?.name ?? '--';
                        return invite;
                    }).sort((a,b) => (a.user_name > b.user_name) ? 1 : ((b.user_name > a.user_name) ? -1 : 0));
                    this.invites = invites;
                } catch (error) {
                    console.log(error);
                    this.notification = error + '!';
                    this.notificationType = 'error';
                } finally {
                    this.loadingInvites = false;
                }
            },
            async createInvite() {
                try {
                    this.error = false;
                    this.loadingCreateInvite = true;
                    await (new HTTP).post('/agency-invites', {
                        name: this.name,
                        configuration: {
                            fb_login: false
                        }
                    });
                    this.name = '';
                } catch (error) {
                    this.error = true;
                    console.log(error);
                } finally {
                    this.loadingCreateInvite = false;
                    this.addResellerDialog = false;
                    this.getInvites();
                }
            }
        }
    };
</script>
